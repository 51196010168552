

.contact-background {
    background: linear-gradient(90deg, #444040  50%,  rgba(255, 255, 255, 0) 50%); }

//#fcd252

.container-who, .container-what, .container-schedule,.container-price {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
    background-color: #fcfbf8; }

.container-contact {
    width: 100%;
    height: auto; }
.animated {
    height: auto; }





