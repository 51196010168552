@mixin bckImage($property, $format) {
    width: 100%;
    height: 100%;
    background-image: url("../../images/" + $property + $format);
    background-size: cover;
    border-radius: 5px;
    -webkit-box-shadow: 7px 7px 7px -5px rgba(0,0,0,0.5);
    -moz-box-shadow: 7px 7px 7px -5px rgba(0,0,0,0.5);
    box-shadow: 7px 7px 7px -5px rgba(0,0,0,0.6); }

.stripe {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 500px;
    background-color: white;
    background-image: url('../../images/stripe-white.png');
    background-size: 150%;
    background-repeat: no-repeat;
    background-position: 50% 100%; }

.stripe-container {
    margin: 1rem 0; }
.stripe-anim {
    display: flex;
    align-items: center;
    width: 20%;
    height: 200px; }

.stripe-text {
    color: #6a6a6a;
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    font-family: 'IM Fell English', serif; }

.st {
    margin: .2rem; }

.stripe-translation {
    color: #6a6a6a;
    text-align: center;
    font-size: 1.5rem;
    font-family: 'IM Fell English', serif; }

.stripe-badge {
    display: flex;
    justify-content: space-around;
    margin-top: 3rem; }

.cont {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }



.no-webp .cont0 {
    @include bckImage("stripeImg1", ".jpg"); }

.no-webp .cont1 {
    @include bckImage("stripeImg4", ".jpg"); }

.no-webp .cont2 {
    @include bckImage("stripeImg3", ".jpg"); }

.no-webp .cont3 {
    @include bckImage("stripeImg2", ".jpg"); }

.webp .cont0 {
    @include bckImage("stripeImg1", ".webp"); }

.webp .cont1 {
    @include bckImage("stripeImg4", ".webp"); }

.webp .cont2 {
    @include bckImage("stripeImg3", ".webp"); }

.webp .cont3 {
    @include bckImage("stripeImg2", ".webp"); }



.stripeText {
    color: white;
    margin: 1vw;
    padding: 1vw;
    font-size: 1.5rem;
    border: 3px solid white;

    &___box1 {
        font-weight: 600; }

    &___box2 {
        font-weight: 600; }

    &___box3 {
        font-weight: 600; }

    &___box4 {
        font-weight: 600; } }


@media (max-width: 1540px) {
    .stripeText {
        font-size: 1.3rem; } }

@media (max-width: 900px) {
    .stripeText {
        font-size: 1.1rem; } }

@media (max-width: 778px) {
    .stripe {
        height: auto; }

    .stripe-badge {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        margin: 0 0 2rem 0; }

    .stripe-anim {
        min-width: 350px;
        padding-top: 1rem; }

    .stripeText {
        margin: 2rem;
        padding: 1rem;
        font-size: 1.5rem; } }


