$cte-blue: #0049ff;
$cte-red: #fc4646;


.Navbar-mobile {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    right: 0;
    left: 0;
    height: 4rem;
    background-color: white;
    border-bottom: 1px solid #e5e0e0;
    -webkit-box-shadow: 0px 3px 6px -1px rgba(0,0,0,0.50);
    -moz-box-shadow: 0px 3px 6px -1px rgba(0,0,0,0.50);
    box-shadow: 0px 3px 6px -1px rgba(0,0,0,0.50);
    z-index: 99999; }

.navbar-container-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 52px;
    padding: 1rem 0;
    &___brand-name-mobile {
        color: #020202; } }

.navbar-menu-container-mobile {
    position: absolute;
    width: 100%;
    left: 0;
    top: 4rem;
    background-color: #f5f5f5;
    &__list {
        display: flex;
        flex-direction: column;
        padding: 0 1.5rem;
        width: 90%;
        cursor: pointer; }
    &__list:first-child {
        border-top: 1px solid #e0dede; } }

.menu-mobile {
    padding: 0.8rem 0;
    border-bottom: 1px solid #e0dede; }

.nav-news-m {
    padding: 0.8rem 0;
    border-bottom: 1px solid #e0dede;
    color: $cte-red;
    font-size: .7rem;
    font-weight: 600; }

.acronym-mobile {
    border: 3px solid;
    border-radius: 3px;
    padding: 0.125rem 0.3125rem;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 900;
    cursor: pointer; }

.menu-icon {
    font-size: 2rem;
    cursor: pointer; }


.lang-container-m {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%; }

.lang-m, .lang-bttn-m {
    font-size: .9rem;
    font-weight: 600; }

.lang-bttn-m {
    margin: 0 1rem;
    color: #b3b3b3; }

.lang-bttn-m:hover {
    color: $cte-blue; }








