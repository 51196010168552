$cte-font: 'Montserrat', sans-serif;


.js-loading, .js-loading:before, .js-loading:after {
    animation-play-state: paused !important; }

html {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow-x: hidden; }
body {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: $cte-font;
    background-color: #fcfbf8; }


h1, h2 {
    margin-block-start: 0;
    margin-block-end: 0; }

ul {
    padding: 0;
    list-style-type: none; }

a {
    text-decoration: none; }


.section-title-wrapper {
    margin: 0;
    text-align: center; }

div {
    box-sizing: border-box; }


@media (min-width: 1550px ) {
    body {
        width: 75%;
        border-left: 1px solid #e5e0e0;
        -webkit-box-shadow: 9px 9px 5px -7px rgba(0,0,0,0.1);
        -moz-box-shadow: 9px 9px 5px -7px rgba(0,0,0,0.1);
        box-shadow: 9px 9px 5px -7px rgba(0,0,0,0.1); } }

