$cte-blue: #0049ff;
$cte-red: #fc4646;

.accordion {
    cursor: pointer;
    margin: .2rem;
    padding: .7rem 0;
    width: 80%;
    text-align: center;
    color: white;
    background-color: $cte-red;
    border-radius: 5px;
    -webkit-box-shadow: 9px 9px 5px -6px rgba(0,0,0,0.1);
    -moz-box-shadow: 9px 9px 5px -6px rgba(0,0,0,0.1);
    box-shadow: 6px 6px 5px -5px rgba(0,0,0,0.2);
    font-weight: 600;
    transition: .3s;
    z-index: 70000; }

.panel-hidden {
    height: 0;
    opacity: 0;
    transition: height .1s, opacity .1s; }

.panel-visible {
    display: flex;
    width: 80%;
    height: 350px;
    opacity: 1;
    background-image: linear-gradient(45deg, #ffffff 3.13%, #f5f5f5 3.13%, #f5f5f5 50%, #ffffff 50%, #ffffff 53.13%, #f5f5f5 53.13%, #f5f5f5 100%);
    border-radius: 10px;
    transition: height .3s, opacity .6s; }


