.post-container {
    width: 95%;
    padding: .5rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    background-color: white;
    border-radius: 5px;
    -webkit-box-shadow: 9px 9px 5px -6px rgba(0,0,0,0.1);
    -moz-box-shadow: 9px 9px 5px -6px rgba(0,0,0,0.1);
    box-shadow: 9px 9px 5px -6px rgba(0,0,0,0.1); }



.post-header, .post-body {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }

.post-header {
    justify-content: space-between;
    align-items: center;
    background-color: #ededed; }

.news-title {
    width: 80%;
    color: #fc4646;
    padding: .1rem 0 .1rem .2rem; }

.news-date-container {
    width: 19%;
    background-color: white;
    background-image: -webkit-repeating-radial-gradient(center center, #e2e0e0, #e2e0e0 1px, transparent 1px, transparent 100%);
    background-image: -moz-repeating-radial-gradient(center center, rgba(0,0,0,.2), rgba(0,0,0,.2) 1px, transparent 1px, transparent 100%);
    background-image: -ms-repeating-radial-gradient(center center, rgba(0,0,0,.2), rgba(0,0,0,.2) 1px, transparent 1px, transparent 100%);
    background-image: repeating-radial-gradient(center center, rgba(255,255,255, 1), rgba(255,255,255, 1) 1px, transparent 1px, transparent 100%);
    -webkit-background-size: 3px 3px;
    -moz-background-size: 3px 3px;
    background-size: 3px 3px; }

.news-date {
    text-align: center;
    height: 100%;
    font-size: .8rem; }

.post-body {
    margin-top: 1rem;
    width: 100%; }

.news-container {
    width: 50%;
    &--title {
        margin: 0;
        font-size: 1.1rem;
        font-weight: 600; }
    &--text {
        width: 90%;
        padding: 0 .5vw;
        text-align: justify; } }

.post-link {
    cursor: pointer;
    font-size: .9rem;
    color: black; }

.post-link:hover {
    color: #0049ff; }

.post-media-container {
    width: 50%;
    height: 300px;
    min-width: 350px; }



@media (max-width: 800px ) {
    .news-container {
        width: 100%;
        margin-bottom: 1rem;
        &--text {
            width: 95%; } }

    .post-media-container {
        width: 100%; } }


