$cte-blue: #0049ff;
$cte-red: #fc4646;

.legals-container {
    position: relative;
    margin: 0 auto;
    width: 80%; }

.spacer {
    width: 100%;
    height: 4rem; }


.legals-title {
    background-color: $cte-blue;
    color: white;
    margin: 2rem 0;
    padding: .5rem; }

.legals-h3 {
    color: $cte-blue; }

.legals-li {
    margin: .3rem 1rem; }

.legal-bottom {
    margin-bottom: 4rem; }

.toLegal {
    color: $cte-blue; }

.toLegal:hover {
    text-decoration: underline; }

.legal-container-EN {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 4rem; }


.underconst-block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: 4rem;
    padding: 2rem;
    width: 60%;
    background-color: $cte-blue;
    border-radius: 10px;
    &__el {
        width: 35%;
        min-width: 200px;
        font-weight: 600;
        color: white;
        &--mod1 {
            margin: 0;
            padding-top: 1rem;
            font-size: 4rem; }
        &--mod2 {
            margin: 0;
            font-size: 2rem; } } }


.gamer {
    padding: 1rem;
    width: 30%;
    min-width: 100px; }

.underconst {
    width: 30%;
    min-width: 100px; }
