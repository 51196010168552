.footer-container {
    width: 100%;
    height: auto;
    background-color: #302d2d; }

.footer-wrapper {
    display: flex;
    justify-content: space-around; }

.footer-wrapper-mbl {
    display: flex;
    justify-content: center; }


.footer-wrapper div {
    margin-top: 1.5rem; }

.footer-wrapper-mbl div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin-top: 1rem; }

.footer-name {
    width: 33%;
    margin: 0;
    font-size: 2.5rem;
    color: white;
    border-left: .5rem solid white;

    &--brand1 {
        font-weight: 700;
        margin: 0; }

    &--brand2 {
        font-weight: 500;
        margin: 0; } }

.logo-wrapper {
    width: 15%; }

.logo-wrapper img {
    width: 100%; }

.logo-wrapper-mbl {
    width: 35%; }

.logo-wrapper-mbl img {
    width: 100%; }

.footer-address {
    color: white;
    margin-top: 1rem; }
.footer-address p {
    margin: 0; }

.footer-address-mbl {
    font-size: .8rem;
    margin-top: 1rem;
    color: white; }

.footer-address-mbl p {
    margin: 0; }

.prefix {
    font-size: .5rem; }
.media {
    font-size: 3rem;
    color: #f5f5f5; }

.media:hover {
    color: #bcbaba; }

.footer-right-text {
    color: #f5f5f5;
    text-decoration: underline; }

.footer-social {
    display: flex;
    align-content: center;
    flex-direction: column;
    width: 25%; }

.media-container {
    display: flex; }

.media-separator {
    padding-right: 2rem; }


.footer-legal {
    margin: 0 1rem;
    display: flex;
    align-items: center;
    color: grey; }


.legals {
    margin: 0 1.5rem; }

.legals span {
    margin: 0 1rem; }

.footer-legal-m {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    color: grey;
    margin: 0 1rem; }

.legals-mob {
    margin: 2rem 0 0 0;
    font-size: .8rem;
    font-weight: 600; }

.legals-mob span {
    margin: 0 1rem; }

.legal-link {
    color: lightgrey; }

.footer-legal-p-mbl {
    font-size: .8rem; }




